import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CampaignPartner } from "../../shared/models/partnerCampaign";
import { ExternalUrl } from "../../shared/models/appData";
import { User } from "../../shared/models/user";
import { UserService } from "../../shared/services/api/user.service";
import {
  LanguageService,
  getTranslatedUrlSignal,
} from "../../shared/services/language.service";
import { AppDataService } from "../../shared/services/api/app-data.service";
import { CampaignService } from "../../shared/services/api/campaign.service";
import { NotificationService } from "../../shared/services/notification.service";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { CustomFormValidators } from "../../shared/validators/custom-form-validators";
import { UserAccessService } from "../shared/services/user-access.service";
import { FeatureFlagsService } from "../../shared/services/feature-flags.service";
import { IntercomService } from "../../shared/services/intercom.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  @Input() public partner?: CampaignPartner;
  @Output() public partnerSignedUp = new EventEmitter<void>();

  public isUnderMaintenance = false;
  public user: User;
  public isTermsAccepted = false;
  public submitting = false;
  public submitted = false;
  public showPassword = false;
  public brandActivationCode?: string;
  public isBrandInvitedUser = false;
  public readonly PASSWORD_PATTERN = CustomFormValidators.PASSWORD_PATTERN;

  private readonly termsUrl = getTranslatedUrlSignal(ExternalUrl.Terms);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appDataService: AppDataService,
    private readonly authenticationService: AuthenticationService,
    private readonly campaignService: CampaignService,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly intercomService: IntercomService,
    private readonly languageService: LanguageService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly userAccessService: UserAccessService,
    private readonly userService: UserService,
  ) {
    this.user = new User();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.email) {
        this.user.email = params.email;
        this.isBrandInvitedUser = true;
      }
    });
  }

  public ngOnInit(): void {
    this.appDataService.get().then((config) => {
      if (config.isUnderMaintenance) {
        this.isUnderMaintenance = true;
      }
    });

    // This is the case for when a brand invites a user
    this.brandActivationCode =
      this.activatedRoute.snapshot.params.activation_code;

    this.isBrandInvitedUser = !!(this.brandActivationCode || this.partner);

    if (this.partner) {
      this.user.email = this.partner.email;
    }
  }

  public signup(): void {
    this.submitting = true;
    // We check on invite action not in constructor because
    // campaign id is not initially set in the parent component
    // is set by the request that may take a few seconds
    // CampaignInviteLayoutComponent
    if (this.campaignService.invitedCampaignId !== undefined) {
      this.user.signUpCampaignId = this.campaignService.invitedCampaignId;
    }

    this.user.locale = this.languageService.locale;

    this.userService
      .signUp(
        this.user.email,
        this.user.password,
        this.user.signUpCampaignId,
        this.brandActivationCode,
        this.user.locale,
        this.partner?.id,
      )
      .subscribe({
        next: () => {
          if (this.partner?.id) {
            this.partnerSignedUp.emit();
          }
          this.login();
        },
        error: (error) => {
          this.submitting = false;
          const errorObject = error;

          if (errorObject?.error?.key === "USER_ALREADY_EXISTS") {
            this.notificationService.error("invite.userAlreadyExists");
            return;
          } else if (errorObject.error && errorObject.error.details) {
            for (const errorName in errorObject.error.details) {
              if (errorName) {
                this.notificationService.error(
                  errorObject.error.details[errorName],
                );
              }
            }
          }

          this.notificationService.error("invite.invitationFailed");
        },
      });
  }

  private login(): void {
    this.authenticationService
      .login(this.user.email, this.user.password)
      .subscribe({
        next: async () => {
          await this.featureFlagsService.load();
          this.refreshUserData();
        },
        error: () => {
          this.submitting = false;
          this.notificationService.error("invite.loginFailed");
        },
      });
  }

  private refreshUserData(): void {
    this.userAccessService.setUserData().subscribe(() => {
      this.submitting = false;
      this.intercomService.init();
      this.router.navigate(["welcome"]);
    });
  }

  public checkTermsLink(event: any): void {
    if (event.target.classList.contains("terms-link")) {
      window.open(this.termsUrl(), "_blank");
    }
  }
}
